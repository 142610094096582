import React from "react";
import ReactMarkdown from "react-markdown";
import Grid from "@material-ui/core/Grid";
import ExtraInfo from "./ExtraInfo";

function Article({ article }) {
  const article_title = article.article_content.title;
  const article_body = article.article_content.body;

  const extraInfo = article.extra_info && article.extra_info.length > 0 ? (
    <div className={"extraWrapper"}>
      {article.extra_info.map((extra, idx) => (
        <ExtraInfo title={extra.title} content={extra.info} key={idx} />
      ))}
    </div>
  ) : null;

  return (
    <div className={"articleWrapper"}>
      <h2 className={"mainQuestion"}>{article_title}</h2>
      <div className={"articleBody"}>
        <Grid container spacing={3} className="articleGrid">
          <Grid item xs={12} className={"fadeIn"}>
            <ReactMarkdown source={article_body} />
          </Grid>
          {extraInfo && (
            <Grid item xs={12} className={"fadeIn extraInfoWrapper"}>
              {extraInfo}
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default Article;

import React, { useState } from "react";

import { useQuery } from "@apollo/react-hooks";
import { getQuestionFromSlug } from "./util/queryConstructor";

import { getQuestionSlug } from "./util/routeUtil";
import { useLocation } from "react-router";
import QuestionTree from "./QuestionTree";
import CircularProgress from "@material-ui/core/CircularProgress";
import NotFound from "./NotFound";
import TreeAnimation from "./TreeAnimation";
import { removeClassById } from "./util/classUtil";

const MAX_STEPS = 7;

function QuestionTreeContainer() {
  removeClassById("mainContainer", "mainAnswer");

  const [step, setStep] = useState(0);

  const updateStep = depth => {
    const newStep = Math.max(0, Math.min(MAX_STEPS - 1, depth));
    if (newStep !== step) {
      setStep(newStep);
    }
  };

  const location = useLocation();

  const slug = getQuestionSlug(location);

  const { data, loading, error } = useQuery(getQuestionFromSlug(slug));

  let content = null;

  if (loading) {
    content = (
      <div className={"progressWrapper"}>
        <CircularProgress color={"secondary"} size={100} />
      </div>
    );
  } else if (error || !data.getTreeQuestion.question) {
    content = <NotFound />;
  } else {
    updateStep(data.getTreeQuestion.depth);

    content = <QuestionTree question={data.getTreeQuestion.question} />;
  }

  return (
    <>
      <TreeAnimation step={step} />
      {content}
    </>
  );
}

export default QuestionTreeContainer;

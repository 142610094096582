import React from "react";
import ReactMarkdown from "react-markdown";
import { useQuery } from "@apollo/react-hooks";
import { getPageFromSlug } from "./util/queryConstructor";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Container } from "@material-ui/core";

import NotFound from "./NotFound";
import Archive from "./Archive";
import SEO from "./SEO";
import {
  getPageTitleFromSlug,
  getPageDescriptionFromSlug
} from "./util/seoUtil";
import { removeClassById } from "./util/classUtil";

const Page = ({ slug }) => {
  removeClassById("mainContainer", "mainAnswer");

  const pageTitle = getPageTitleFromSlug(slug);
  const pageDescription = getPageDescriptionFromSlug(slug);

  const { data, loading, error } = useQuery(getPageFromSlug(slug));

  let content = null;

  if (loading) {
    content = (
      <div className={"progressWrapper"}>
        <CircularProgress color={"primary"} size={100} />
      </div>
    );
  } else if (error || data.sitePages.length < 1) {
    content = <NotFound />;
  } else if (slug === "arkiv") {
    content = (
      <Archive
        title={data.sitePages[0].title}
        copy={data.sitePages[0].content[0].copy}
      />
    );
  } else {
    content = renderSiteContent(data);
  }

  return (
    <Container className={"pageWrapper"}>
      <SEO title={pageTitle} description={pageDescription} />
      {content}
    </Container>
  );
};

const renderSiteContent = data => {
  const pageContent = data.sitePages[0];

  const title = pageContent.title;

  const content = pageContent.content.map((content, idx) => {
    const renderedCopy = content.copy ? (
      <ReactMarkdown className={"contentCopy"} source={content.copy} />
    ) : null;
    const renderedMedia = content.media ? (
      <div className={"contentImageWrapper"}>
        <img
          src={process.env.REACT_APP_BACKEND_URL + "/" + content.media.url}
          className={"contentImage"}
          alt={"Bilde"}
        />
      </div>
    ) : null;

    if (renderedCopy && renderedMedia) {
      return (
        <div key={idx} className={"contentBlock siteCopyAndImage"}>
          {renderedCopy}
          {renderedMedia}
        </div>
      );
    } else if (renderedCopy) {
      return (
        <div key={idx} className={"contentBlock siteCopy"}>
          {renderedCopy}
        </div>
      );
    } else if (renderedMedia) {
      return (
        <div key={idx} className={"contentBlock siteImage"}>
          {renderedMedia}
        </div>
      );
    }

    return null;
  });

  return (
    <div>
      {title && <h2 className={"pageTitle"}>{title}</h2>}
      {content}
    </div>
  );
};

export default Page;

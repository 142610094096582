import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className={"notFoundWrapper"}>
      <h2 className={"notFoundTitle"}>Siden finnes ikke (404)</h2>
      <div className={"notFoundContent"}>
        <p>Siden du leter etter er flyttet eller eksisterer ikke.</p>
        <p>
          <Link to="/" className="backToStartLink">
            Gå til startsiden
          </Link>
        </p>
      </div>
    </div>
  );
}

export default NotFound;

import React from "react";
import "./App.css";
import NavigationBar from "./NavigationBar";
import QuestionTreeContainer from "./QuestionTreeContainer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Container } from "@material-ui/core";
import Footer from "./Footer";
import Page from "./Page";
import FooterArt from "./FooterArt";
import SEO from "./SEO";

function App() {
  return (
    <Container className="App">
      <SEO />
      <Router>
        <header className="App-header">
          <NavigationBar />
        </header>
        <Container
          maxWidth="lg"
          className={"mainContainer"}
          id={"mainContainer"}
        >
          <Switch>
            <Route exact path="/arkiv">
              <Page slug={"arkiv"} />
            </Route>
            <Route exact path="/om-oss">
              <Page slug={"om-oss"} />
            </Route>
            <Route exact path="/kontakt">
              <Page slug={"kontakt"} />
            </Route>
            <Route path="/">
              <QuestionTreeContainer />
            </Route>
          </Switch>
          <div className={"footerArtContainer"}>
            <FooterArt />
          </div>
        </Container>
        <Footer />
      </Router>
    </Container>
  );
}

export default App;

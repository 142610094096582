export const getPageTitleFromSlug = slug => {
  const replacedDashes = slug.replace(/-/g, " ");
  const formatted =
    replacedDashes.charAt(0).toUpperCase() + replacedDashes.slice(1);
  return "Lommeloven | " + formatted;
};

export const getPageTitleFromArticle = article => {
  const articleTitle =
    article &&
    article.article_content.title &&
    article.article_content.include_in_archive
      ? article.article_content.title
      : null;

  if (articleTitle && articleTitle !== "") {
    return "Lommeloven | " + articleTitle;
  }

  return "Lommeloven | Rett i lomma";
};

export const getPageDescriptionFromSlug = slug => {
  switch (slug) {
    default:
      return "Lommeloven - Rett i lomma";
  }
};

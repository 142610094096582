import React from "react";
import ModalButton from "./ModalButton";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";

function ExtraInfo({ title, content }) {
  if (!content) {
    return null;
  }

  const rendered_content = content.map((src, idx) => (
    <ModalButton
      button_text={src.button_text}
      content_title={src.title}
      content={src.body}
      key={idx}
    />
  ));

  return (
    <ExpansionPanel className={"expansionPanel"}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon color={"secondary"} />}
        className={"expansionPanelSummary"}
      >
        <h5 className={"extraInfoTitle"}>{title}</h5>
      </ExpansionPanelSummary>

      <Divider />

      <ExpansionPanelDetails className={"expansionPanelDetails"}>
        {rendered_content}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default ExtraInfo;

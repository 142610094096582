import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, lang = "nb" }) => {
  const safeTitle = title ? title : "Lommeloven | Rett i lomma";
  const safeDescription = description
    ? description
    : "Lommeloven - Rett i lomma";

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={safeTitle}
      meta={[
        {
          name: `description`,
          content: safeDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: safeDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: safeDescription
        }
      ]}
    />
  );
};

export default SEO;

import React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Icon } from "./Icon";

function Answer({ icon, answer, slug, isBackButton }) {
  const iconType = icon && icon.type ? icon.type : "";

  const linkClass =
    isBackButton === true
      ? "answerLink fadeIn backButton"
      : "answerLink fadeIn";

  return (
    <Link to={"/" + slug} className={linkClass}>
      <CardActionArea className={"answerCardActionArea"}>
        <div className={"answerIconWrapper"}>
          <Icon type={iconType} />
        </div>
        <div className={"answerTextWrapper"}>
          <Typography component="h2" className={"answerText"}>
            {answer}
          </Typography>
        </div>
      </CardActionArea>
    </Link>
  );
}

export default Answer;

import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CardActionArea from "@material-ui/core/CardActionArea";
import ReactMarkdown from "react-markdown";
import Slide from "@material-ui/core/Slide";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

function ModalButton({ button_text, content_title, content }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getWindowWidth() {
    const { innerWidth: width } = window;
    return width;
  }

  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowWidth());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const modalContent = (
    <div className={"modalContent"}>
      <div className={"closeButtonWrapper"}>
        <IconButton
          onClick={handleClose}
          aria-label="closeModal"
          className={"closeModalButton"}
        >
          <CancelIcon fontSize="large" color={"secondary"} />
        </IconButton>
      </div>

      <h2 className={"modalTitle"}>{content_title}</h2>
      <OverlayScrollbarsComponent
        className={"modalOverlayScrollbar"}
        options={{
          paddingAbsolute: true,
          resize: "none",
          scrollbars: {
            autoHide: "scroll",
            autoHideDelay: 1600
          }
        }}
      >
        <ReactMarkdown className={"modalCopy"} source={content} />
      </OverlayScrollbarsComponent>
    </div>
  );

  // Slide up on mobile
  const modalContentWrapper =
    windowWidth > 550 ? (
      <Fade in={open}>{modalContent}</Fade>
    ) : (
      <Slide direction="up" in={open}>
        {modalContent}
      </Slide>
    );

  return (
    <div className={"modalButtonWrapper"}>
      <CardActionArea onClick={handleOpen} className={"modalButton"}>
        <div className={"extraOptionsWrapper"}>
          <Typography component="h2" className={"extraInfoText"}>
            {button_text}
          </Typography>
        </div>
      </CardActionArea>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={"infoModal"}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200
        }}
      >
        {modalContentWrapper}
      </Modal>
    </div>
  );
}

export default ModalButton;

import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { getAllAnsweredQuestions } from "./util/queryConstructor";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import NotFound from "./NotFound";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import Button from "@material-ui/core/Button";

const Archive = ({ title, copy }) => {
  const { data, loading, error } = useQuery(getAllAnsweredQuestions);

  let content = null;

  if (loading) {
    content = (
      <div className={"progressWrapper"}>
        <CircularProgress color={"primary"} size={100} />
      </div>
    );
  } else if (error || data.getAllAnswered.length < 1) {
    content = <NotFound />;
  } else {
    content = (
      <div className={"fadeIn"}>
        <h2 className={"pageTitle"}>{title}</h2>
        <ReactMarkdown source={copy} className={"contentBlock siteCopy"} />
        <div className={"archiveWrapper"}>
          {renderSiteContent(data.getAllAnswered)}
        </div>
      </div>
    );
  }

  return <div className={"fadeIn"}>{content}</div>;
};

const renderSiteContent = answered => {
  const categories = answered
    .map(a => a.category)
    .filter((category, index, array) => category && array.indexOf(category) === index)
    .sort();

  return categories.map((category, index) => (
    <ExpansionPanel className={"expansionPanel"} key={index}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon color={"secondary"} />}
        className={"expansionPanelSummary"}
      >
        <h5 className={"archiveCategoryTitle"}>{category}</h5>
      </ExpansionPanelSummary>

      <Divider />

      <ExpansionPanelDetails className={"expansionPanelDetails"}>
        {answered.map((a, idx) => {
          if (a.category !== category) {
            return null;
          }

          return (
            <div className={"archiveButtonWrapper"} key={idx}>
              <Link
                to={{
                  pathname: "/" + a.slug,
                  state: { fromArchive: true }
                }}
                className={"archiveLink"}
              >
                <Button className={"archiveButton"}>
                  <div className={"archiveEntryWrapper"}>
                    <Typography component="h2" className={"archiveEntryText"}>
                      {a.full_answer}
                    </Typography>
                    <ArrowForwardIcon
                      color={"secondary"}
                      className={"archiveForwardIcon"}
                    />
                  </div>
                </Button>
              </Link>
            </div>
          );
        })}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  ));
};

export default Archive;

import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

function Footer() {
  return (
    <footer className={"footer"}>
      <Container maxWidth="sm">
        <Typography variant="body2" color="primary">
          Copyright © Lommeloven {new Date().getFullYear()}.
        </Typography>
      </Container>
    </footer>
  );
}

export default Footer;

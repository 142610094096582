import gql from "graphql-tag";

export const getQuestionFromSlug = slug => {
  const safe_slug = slug || "index";

  return gql`
      {
          getTreeQuestion(slug: "${safe_slug}") {
              depth
              question {

                  main_question
                  answer
                  icon {
                      type
                  }
                  article {
                      article_content {
                          title
                          body
                          include_in_archive
                      }
                      extra_info {
                          title
                          info {
                              button_text
                              body
                              title
                          }
                      }
                  }
                  children(where: { publish: true }) {
                      slug
                      answer
                      icon {
                          type
                      }
                  }
                  parent {
                      slug
                      icon {
                          type
                      }
                  }
              }
          }
      }
  `;
};

export const getPageFromSlug = slug => {
  return gql`
      {
          sitePages(where: { slug: "${slug}"}) {
              title
              content {
                  copy
                  media {
                      url
                  }
              }
          }
      }
  `;
};

export const getAllAnsweredQuestions = gql`
  {
    getAllAnswered {
      slug
      full_answer
      category
    }
  }
`;

export const getFirstLevelCategories = gql`
  {
    questions(where: { slug: "index", publish: true }) {
      children(where: { publish: true }) {
        slug
        answer
      }
    }
  }
`;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompass,
  faCreditCard,
  faHandshake,
  faHourglass,
  faBuilding,
  faUser,
  faClock
} from "@fortawesome/free-regular-svg-icons";
import {
  faHandshakeSlash,
  faGavel,
  faHardHat,
  faHourglassHalf,
  faBriefcase,
  faBusinessTime,
  faBalanceScale,
  faBalanceScaleLeft,
  faBarcode,
  faShoppingBasket,
  faCar,
  faMoneyBillWave
} from "@fortawesome/free-solid-svg-icons";

import React from "react";

export function Icon({ type, className }) {
  let icon = null;

  const safeClassName = className ? className : "answerIcon";

  switch (type) {
    case "compass":
      icon = faCompass;
      break;
    case "credit-card":
      icon = faCreditCard;
      break;
    case "gavel":
      icon = faGavel;
      break;
    case "handshake":
      icon = faHandshake;
      break;
    case "handshake-slash":
      icon = faHandshakeSlash;
      break;
    case "hard-hat":
      icon = faHardHat;
      break;
    case "hourglass":
      icon = faHourglass;
      break;
    case "hourglass-half":
      icon = faHourglassHalf;
      break;
    case "briefcase":
      icon = faBriefcase;
      break;
    case "business-time":
      icon = faBusinessTime;
      break;
    case "building":
      icon = faBuilding;
      break;
    case "balance-scale":
      icon = faBalanceScale;
      break;
    case "balance-scale-left":
      icon = faBalanceScaleLeft;
      break;
    case "barcode":
      icon = faBarcode;
      break;
    case "shopping-basket":
      icon = faShoppingBasket;
      break;
    case "car":
      icon = faCar;
      break;
    case "user":
      icon = faUser;
      break;
    case "clock":
      icon = faClock;
      break;
    case "money-bill-wave":
      icon = faMoneyBillWave;
      break;
    default:
      icon = faBalanceScale;
  }

  return (
    <FontAwesomeIcon icon={icon} color={"#71498d"} className={safeClassName} />
  );
}

import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Container } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import { useQuery } from "@apollo/react-hooks";
import { getFirstLevelCategories } from "./util/queryConstructor";
import Logo from "./Logo.svg";

function NavigationBar() {
  const [open, setOpen] = useState(false);

  const { data } = useQuery(getFirstLevelCategories);

  const dropdownRef = useRef(null);

  // Effect for handling clicks outside of dropdown when open.
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        open &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, open]);

  const dropdownItemsRendered = (
    <div>
      <Link to="/arkiv" className="navbarLink">
        Arkiv
      </Link>
      {data &&
        data.questions.length > 0 &&
        data.questions[0].children.map((item, idx) => (
          <Link to={item.slug} className="navbarLink" key={idx}>
            {item.answer}
          </Link>
        ))}
      <Link to="/kontakt" className="navbarLink">
        Kontakt
      </Link>
      <Link to="/om-oss" className="navbarLink">
        Om oss
      </Link>
    </div>
  );
  const navDropdownTitle = (
    <MenuIcon className={"navBurger"} color={"primary"}>
      Dropdown
    </MenuIcon>
  );

  return (
    <Container>
      <Navbar bg="#fffff" expand="" className={"navigationBar"}>
        <div>
          <Link to="/" className="brandLink">
            <img
              alt={"Lommeloven logo"}
              className={"navigationLogo"}
              src={Logo}
            />
          </Link>
        </div>
        <div
          ref={dropdownRef}
          className={"navDropdown" + (open ? " navDropdown-open" : "")}
        >
          <IconButton aria-label="menu" onClick={() => setOpen(!open)}>
            {navDropdownTitle}
          </IconButton>
          {open && dropdownItemsRendered}
        </div>
      </Navbar>
    </Container>
  );
}

export default NavigationBar;

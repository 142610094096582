import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const images = [
  "./treePieces/tree1.png",
  "./treePieces/tree2.png",
  "./treePieces/tree3.png",
  "./treePieces/tree4.png",
  "./treePieces/tree5.png",
  "./treePieces/tree6.png",
  "./treePieces/tree7.png"
];

function TreeAnimation({ step }) {
  const branches = images.map((img, index) => {
    if (index <= step) {
      return (
        <CSSTransition key={index} timeout={500} classNames="stepBranch">
          <img
            alt={"tree step"}
            className={"stepTree"}
            src={img}
            style={{ zIndex: 50 - index }}
          />
        </CSSTransition>
      );
    }

    return null;
  });

  return (
    <div className={"animationContainer"}>
      <TransitionGroup>{branches}</TransitionGroup>
    </div>
  );
}

export default TreeAnimation;

import Answer from "./Answer";
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import { Icon } from "./Icon";

export function BackButton({ slug, answer, icon }) {
  const [width, setWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  const iconType = icon && icon.type ? icon.type : "";

  const bigScreen = width > 700;

  const wrapperClass = bigScreen
    ? "backButtonWrapper"
    : "backButtonWrapper small";

  const content = bigScreen ? (
    <Answer slug={slug} answer={answer} icon={icon} isBackButton={true} />
  ) : (
    <Link to={slug !== "index" ? slug : "/"}>
      <Button aria-label="goBack" className={"smallBackButton"}>
        <ArrowBackIcon className={"backButtonIcon"} />
        <Icon type={iconType} className={"backButtonIcon"} />
        {answer}
      </Button>
    </Link>
  );

  return <div className={wrapperClass}>{content}</div>;
}

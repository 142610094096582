import React from "react";
import Answer from "./Answer";
import Article from "./Article";
import NotFound from "./NotFound";
import { BackButton } from "./BackButton";
import SEO from "./SEO";
import { getPageTitleFromArticle } from "./util/seoUtil";
import { addClassById } from "./util/classUtil";

function QuestionTree({ question }) {
  const main_question = question.main_question;
  const answers = question.children;
  const article = question.article;

  if (article && article.article_content.include_in_archive) {
    addClassById("mainContainer", "mainAnswer");
  }

  const pageTitle = getPageTitleFromArticle(article);

  const mainQuestionClass =
    article && answers.length > 0 ? "continueQuestion" : "";

  if ((!answers || answers.length === 0) && !article) {
    return <NotFound />;
  }

  const backButton =
    question.parent && question.parent.slug ? (
      <BackButton
        slug={question.parent.slug}
        answer={question.answer}
        icon={question.icon}
      />
    ) : null;

  return (
    <>
      <SEO title={pageTitle} />
      {backButton}

      <div className={"fullQuestionWrapper"}>
        {article && <Article article={article} />}

        {article && answers.length > 0 && (
          <div className={"separatorWrapper"}>
            <div className={"separator"} />
          </div>
        )}

        {answers.length > 0 && (
          <div className={"questionWrapper"}>
            <h2 className={"mainQuestion " + mainQuestionClass}>
              {main_question}
            </h2>
            <div className={"answerOuterWrapper"}>
              <div className={"answerWrapper"}>
                <div className={"answerRowContainer"}>
                  {answers.map((answer, idx) => (
                    <Answer
                      slug={answer.slug}
                      answer={answer.answer}
                      icon={answer.icon}
                      key={idx}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default QuestionTree;

import React, { useEffect, useState } from "react";

export default function FooterArt() {
  return (
    <svg
			className={"footerArt"}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="208.4 180 1000 159"
    >
      <polygon
        className="st0"
        points="1208.4,191.7 660.4,234.7 208.4,338.7 1208.4,338.7 "
      />
      <polygon className="st1" points="208.4,194.7 208.4,338.7 660.4,234.7 " />
    </svg>
  );
}

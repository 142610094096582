export const removeClassById = (elementId, classToRemove) => {
  const element = document.getElementById(elementId);

  if (element) element.classList.remove(classToRemove);
};

export const addClassById = (elementId, classToAdd) => {
  const element = document.getElementById(elementId);

  if (element) element.classList.add(classToAdd);
};
